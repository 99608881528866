import React, { FC, useEffect, useState } from 'react'
import update from 'immutability-helper'
import { useNavigate, useParams } from 'react-router-dom'
import { getGroupsToCreate, getSelectedStepAssignment, applicationCutOff, NotFound404, ErrorNotice, FlowCall, CallPartitionResource, NewModal, canEditAssignment, FlowAssignment, User, FlowFormElement, FLOW_STATUS, ApplicationFlowStep, ApplicationCall, ApplicationFormElement, CollapseItem, ELEMENT_TYPES, ElementValue, newTheme, SvgIcon, AssignmentData, validateAssignment, ApiBaseError, AssignmentInput, NotificationType, NewLoading as Loading, ApplicationDocument, FlowApplication, FORM_ELEMENT_TYPES, AssignmentDataUpload, ApplicationStep, transformForms, getConditionsValues, ROLES } from 'prace-common-components'
import {
  $CallApplication,
  $Chip,
  $PageTitle,
  $ProposalID,
  $CallImage,
  $AlertContent,
  $Warning,
  $WarningContainer,
  $WarningGrid,
  $FormContainer,
} from './styles'
import Grid from '@material-ui/core/Grid'
import { Banner } from 'components/Banner'
import { RejectModal } from 'components/RejectModal'
import { AssignModal } from 'components/AssignModal'
import { AcceptModal } from 'components/AcceptModal.tsx'
import moment from 'moment'
import { useAcceptAssignmentMutation, useAssignMutation, useDeleteApplicationMutation, useDeleteAssignmentDataMutation, useDeleteUploadMutation, useGetApplicationQuery, useGetDocumentsQuery, useListOptionsQuery, useSaveAssignmentMutation, useSubmitAssignmentMutation, useUpdateLeadAssignmentMutation, useUploadFileMutation } from 'store/api/applications'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { RootState } from 'store'
import { useLazyDownloadPdfQuery, useLazyDownloadFileQuery } from 'store/api/downloads'
import { __PROD__, CDN_AVATARS, HPC_DOMAIN, isEuroHPC, Routes } from 'constants/global'
import { getCallStatus } from 'util/getCallStatus'
import { CallFlowContainer } from './CallFlowContainer'
import { AssignmentFlowContainer } from './AssignmentFlowContainer'
export interface CallApplicationState extends Omit<FlowApplication, 'call' | 'user'> {
  call?: ApplicationCall,
  id: FlowApplication['id'],
  uid: FlowApplication['uid'],
  user?: FlowApplication['user'],
  ownerId: number,
  unlocked: FlowApplication['unlocked'],
  needSave: boolean,
  editorSelector: {
    step: number,
    form: number,
    assignment: number,
    workingStep: number,
  },
}

export const CallApplication: FC<{isMobile?: boolean}> = ({ isMobile }) => {
  const { uid } = useParams<{ uid: string }>()
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const [downloadFile] = useLazyDownloadFileQuery()
  const [downloadPdf] = useLazyDownloadPdfQuery()
  const [uploadFile, { isLoading: isUploadingFile }] = useUploadFileMutation()
  const [deleteUpload, { isLoading: isDeletingUpload }] = useDeleteUploadMutation()
  const [deleteApp, { isLoading: isDeleting }] = useDeleteApplicationMutation()
  const [acceptAssignment, { isLoading: isAccepting }] = useAcceptAssignmentMutation()
  const [assignUser, { isLoading: isAssigning }] = useAssignMutation()
  const [submitAssignment, { isLoading: isSubmitting }] = useSubmitAssignmentMutation()
  const [updateLeadAssignment, { isLoading: isUpdatingLead }] = useUpdateLeadAssignmentMutation()
  const [deleteAssignmentData, { isLoading: isDeletingData }] = useDeleteAssignmentDataMutation()
  const [saveAssignment, { isLoading: isSaving }] = useSaveAssignmentMutation()
  const [openDrawer, setOpenDrawer] = React.useState<boolean>(false)

  const [callApplication, setCallApplication] = useState<CallApplicationState>({
    id: 0,
    uid: '',
    ownerId: 0,
    unlocked: [],
    user: undefined,
    call: undefined,
    needSave: false,
    editorSelector: {
      step: 0,
      form: 0,
      assignment: 0,
      workingStep: 0,
    },
  })
  const [documents, setDocuments] = useState<ApplicationDocument[]>([])
  const [canDelete, setCanDelete] = useState<boolean>(false)
  const [draft, setDraft] = useState<boolean>(false)
  const [rejectOpen, setRejectOpen] = useState<boolean>(false)
  const [acceptOpen, setAcceptOpen] = useState<boolean>(false)
  const [assignOpen, setAssignOpen] = useState<boolean>(false)
  const [assignOtherOpen, setAssignOtherOpen] = useState<boolean>(false)
  const customLoading = useAppSelector((state: RootState) => state.loading.value)
  const profile = useAppSelector((state: RootState) => state.profile.user as unknown as User)
  const { step = 0, form = 0, assignment = 0, workingStep = 0 } = callApplication.editorSelector
  const call = callApplication?.call as ApplicationCall
  const user = callApplication?.user as User
  const flowStep = call?.flow[step] || {}
  const {assignments = [], permissions = { assign: [], assignable: [] }} = flowStep
  const assignmentOwnersStatus = assignments.map((a) => ({
    ownerId: a.ownerId || a.owner?.id,
    status: a.status,
    partitionId: a.partitionId,
    assignerId: a.assignerId || a.assigner?.id,
  }))

  const { data: apiApplication, isLoading, isFetching, isUninitialized, error, refetch } = useGetApplicationQuery(
    uid || '', { skip: !uid, refetchOnMountOrArgChange: true })
  const { 
    data: apiDocuments,
    isLoading: isLoadingDocs,
    isFetching: isFetchingDocs,
  } = useGetDocumentsQuery(callApplication.id)
  const { data: optionsGroupsData, isFetching: loadingOptions } = useListOptionsQuery({ offset: 0 })
  const options_groups = optionsGroupsData?.data || []

  const loading = isLoading || isFetching || isUninitialized || loadingOptions || 
    isAccepting || isUploadingFile || isDeletingUpload || isDeleting || 
    isAssigning || isSubmitting || isUpdatingLead || isDeletingData ||
    isDeletingData || isSaving || isLoadingDocs|| isFetchingDocs || customLoading

  /* FIXME: This will be removed later */
  useEffect(() => {
    if(__PROD__ && !isEuroHPC && uid?.includes('HPC')) {
      // Redirect to HPC domain
      window.location.href = `${HPC_DOMAIN}/applications/${uid}`
    }
  }, [uid])
  
  useEffect(() => {
    if(apiApplication)
      setCallApplication((prevApp) => {
      /* If this is not the first time requesting  */
        const sameSelection = prevApp.id == apiApplication.id
        const {
          editingAssign,
          editing,
          moreAdvancedAssignment,
          canDelete,
          newDraft,
          updatedApplication,
        } = getSelectedStepAssignment(apiApplication.user as User, apiApplication)
        const selects = editingAssign ? editing : moreAdvancedAssignment
        const step = sameSelection ? prevApp.editorSelector.step : selects.step
        const assignment = sameSelection ? prevApp.editorSelector.assignment : selects.assignment
        const flowStep = updatedApplication.call.flow[step] as ApplicationStep
        let newApplication = updatedApplication
        if(flowStep?.flow) {
          newApplication = update(updatedApplication, {
            call: {
              flow: {
                [step]: {
                  flow: { $set: transformForms(flowStep, assignment) },
                }}}})
        }
        setCanDelete(canDelete)
        setDraft(newDraft)
        return {
          ...newApplication,
          needSave: false,
          editorSelector: sameSelection ? prevApp.editorSelector :
            { ...prevApp.editorSelector, step, assignment, workingStep: step },
        }
      })

  }, [apiApplication])

  useEffect(() => {
    setDocuments(apiDocuments || [])
  }, [apiDocuments])

  const changeSelection = (order: number, type: string) => {
    const isStep: boolean = type === 'step'
    const isAssignment: boolean = type === 'assignment'
    setCallApplication((prevApp) => {
      const { step, assignment } = prevApp.editorSelector
      if(!prevApp.call) return prevApp
      const flowStep: ApplicationStep = isStep ? prevApp.call.flow[order] : prevApp.call.flow[step]
      let selectAssignment = 0
      if(isStep) { // Find the user assignment, if there aren't just show first
        for(let i = 0; i < (flowStep.assignments || []).length; i++) {
          if(flowStep.assignments[i]?.ownerId == prevApp.user?.id) selectAssignment = i
        }
      }
      let newCall = prevApp.call
      if(type !== 'form') {
        newCall = update(prevApp.call, {
          flow: {
            [isStep ? order : step]: {
              flow: { $set: transformForms(flowStep, isStep ? selectAssignment : isAssignment ? order : assignment) },
            }}})
      }
      return update(prevApp, {
        call: { $set: newCall },
        editorSelector: {
          form: { $set: isStep || isAssignment ? 0 : prevApp.editorSelector.form },
          assignment: { $set: isStep ? selectAssignment : prevApp.editorSelector.assignment },
          [type]: { $set: order },
        },
      })})
  }

  //TODO: This handler uses currentassignment
  //TODO: Find way to do this changeFile in a better way
  const onChangeFile = async (
    childElementId: number,
    file: Nullable<File>,
    uploadId?: number,
    groupElementOrder?: number,
    groupId?: number,
    instance?: number,
  ) => {
    if(!currentAssignment) return
    const foundDataGroup = currentAssignment.data.find(
      (data: AssignmentData) => ((data.flowElementId == groupId) && (data.input?.order == instance)))
    let foundDataGroupId = foundDataGroup?.id
    if(file) {
      let assignmentDatas = currentAssignment.data

      /* Save everything before uploading file to make sure we have everything synced */
      if((!foundDataGroup || foundDataGroup.feId || foundDataGroup.needCreate) && groupId && childElementId) {
        const { newGroups, allSavedIds } = getGroupsToCreate(currentAssignment.data, foundDataGroupId)
        //Only send assignmentDatas with needSave flag
        const saveDatas = currentAssignment.data.filter((data) => !!data.needSave && !data.feId && !data.needCreate)
        allSavedIds.push(...saveDatas.map((d) => d.id))
        const newDatas = await saveAssignmentApi(currentAssignment.id, allSavedIds, false, saveDatas, newGroups)
        if(!newDatas) return
        foundDataGroupId = newDatas?.find((d) =>
          (d.flowElementId == groupId) && (d.input?.order == instance))?.id
        assignmentDatas = [...currentAssignment.data.filter(
          (d) => !((d.flowElementId == groupId) && (d.input?.order == instance))), ...newDatas]
      }

      const data = new FormData()
      data.append('files', file)
      data.append('flowElementId', String(childElementId))

      if(childElementId && foundDataGroupId && groupId) { // Found Group element
        data.append('groupId', String(foundDataGroupId))
        const uploadAssignmentData = await uploadFile({ id: currentAssignment.id, data }).unwrap()
        const uploadId = uploadAssignmentData.upload?.id
        const foundDataIdx = assignmentDatas.findIndex(
          (data: AssignmentData) => data.id === uploadAssignmentData.id)
        if(foundDataIdx > -1 && uploadAssignmentData?.id) { // See if assignmentData exists
          setCallApplication((prevApp) => changeGroupAssignmentValue(
            prevApp,
            groupId,
            groupElementOrder!,
            uploadAssignmentData.id!,
            instance!,
            String(uploadId),
            FORM_ELEMENT_TYPES.UPLOAD,
          ))
        } else {
          setCallApplication((prevApp) => addGroupAssignmentData(prevApp,
            [uploadAssignmentData],
            groupId,
            groupElementOrder!,
            instance!,
            FORM_ELEMENT_TYPES.UPLOAD,
            String(uploadId),
            true,
          ))
        }
        setDocuments((prevDocs) => {
          const filteredDocs = prevDocs.filter((doc) => String(doc.id) != String(uploadId)) || []
          filteredDocs.push(uploadAssignmentData.upload as ApplicationDocument)
          return filteredDocs
        })
        dispatch({ type: 'notification', payload: { type: NotificationType.success, msg: 'File uploaded' } })
      } else { // Normal element
        const uploadAssignmentData = await uploadFile({ id: currentAssignment.id, data }).unwrap()
        const uploadId = uploadAssignmentData.upload?.id
        const foundData = assignmentDatas.find(
          (data: AssignmentData) => data.flowElementId === childElementId)
        if(foundData) { // See if assignmentData exists
          onChangeValue(String(uploadId), childElementId, false, uploadAssignmentData)
        } else {
          addAssignmentData(uploadAssignmentData, childElementId, String(uploadId))
        }
        setDocuments((prevDocs) => {
          const filteredDocs = prevDocs.filter((doc) => String(doc.id) != String(uploadId)) || []
          filteredDocs.push(uploadAssignmentData.upload as ApplicationDocument)
          return filteredDocs
        })
        dispatch({ type: 'notification', payload: { type: NotificationType.success, msg: 'File uploaded' } })
      }
    } else {
      const document = documents.find((document) => document.id == uploadId)
      if(!document || !uploadId || !currentAssignment) return
      const updatedData = await deleteUpload({ id: currentAssignment.id, uploadId }).unwrap()
      if(foundDataGroupId && groupId) {
        setDocuments((prevDocs) => prevDocs.filter((doc) => doc.id !== document.id))
        setCallApplication((prevApp) => {
          const { step, form, assignment } = prevApp.editorSelector
          const currentAssignment = prevApp.call?.flow[step].assignments[assignment]
          if(!prevApp.call || !currentAssignment || (instance === undefined)) return prevApp
          const elementIdx = prevApp.call.flow[step].flow[form].flow.findIndex(
            (el: ApplicationFormElement) => el.id === groupId)
          const updatedDatas = currentAssignment.data.filter( // Remove childData
            (data) => !(data.flowElementId === childElementId && data.dataGroupId === foundDataGroupId))
          updatedDatas.push(updatedData)
          return update(prevApp, {
            call: {
              flow: {
                [step]: {
                  assignments: {
                    [assignment]: {
                      data: { $set: updatedDatas },
                    },
                  },
                  flow: {
                    [form]: {
                      flow: {
                        [elementIdx]: {
                          instances: {
                            [instance]: {
                              childInstances: {
                                [groupElementOrder!]: {
                                  value: { $set: null },
                                  elementType: { $set: FORM_ELEMENT_TYPES.UPLOAD },
                                }}}}}}}},
                },
              },
            },
          })})
      } else {
        /* Remove filename from flow element value */
        setDocuments((prevDocs) => prevDocs.filter((doc) => doc.id !== document.id))
        setCallApplication((prevApp) => {
          const { step, form, assignment } = prevApp.editorSelector
          const currentAssignment = prevApp.call?.flow[step].assignments[assignment]
          if(!currentAssignment || (instance === undefined)) return prevApp
          const elementIdx = prevApp.call!.flow[step].flow[form].flow.findIndex(
            (el: ApplicationFormElement) => el.id === groupId)
          const updatedDatas = currentAssignment.data.filter(
            (assignmentData) => assignmentData.flowElementId !== childElementId)
          updatedDatas.push(updatedData)
          return update(prevApp, {
            call: {
              flow: {
                [step]: {
                  assignments: {
                    [assignment]: {
                      data: { $set: updatedDatas },
                    },
                  },
                  flow: {
                    [form]: {
                      flow: {
                        [elementIdx]: {
                          value: { $set: null }}}}},
                },
              },
            },
          })})
      }
      dispatch({ type: 'notification', payload: { type: NotificationType.success, msg: 'File deleted' } })
    }
  }

  const changeGroupAssignmentValue = (
    prevApp: CallApplicationState,
    groupId: number,
    groupElementOrder: number,
    assignmentDataId: number | AssignmentData,
    instance: number,
    value: ElementValue,
    elementType: FORM_ELEMENT_TYPES,
    upload?: AssignmentDataUpload,
    needSave = true,
    updatedData?: Partial<AssignmentData>,
  ) => {
    const values = Array.isArray(value) ? value : [String(value)]
    const { step, form, assignment } = prevApp.editorSelector
    if(!prevApp.call) return prevApp
    const currentAssignment = prevApp.call.flow[step].assignments[assignment]
    const elementIdx = prevApp.call.flow[step].flow[form].flow.findIndex(
      (el: ApplicationFormElement) => el.id === groupId)
    /* If the assignment data is not present in the db, we have no id yet */
    const assignmentDataIdx = (typeof assignmentDataId === 'object') ? 
      currentAssignment?.data.findIndex(
        (data) => (data.flowElementId === assignmentDataId.flowElementId) && 
        (data.dataGroupId === assignmentDataId.dataGroupId))
      : currentAssignment?.data.findIndex((data) => data.id === assignmentDataId)
    if(!currentAssignment || elementIdx === -1 ||  assignmentDataIdx === -1) return prevApp
    return update(prevApp, {
      needSave: { $set: needSave ? true : prevApp.needSave },
      call: {
        flow: {
          [step]: {
            assignments: {
              [assignment]: {
                data: { // Change the corresponding AssignmentData for the BE
                  $apply: (datas: AssignmentData[]) => {
                    datas.splice(assignmentDataIdx, 1, {
                      ...datas[assignmentDataIdx],
                      needSave,
                      input: {
                        ...datas[assignmentDataIdx].input,
                        elementType,
                        values,
                        upload,
                      } as AssignmentInput,
                      ...(updatedData || {}),
                    })
                    return datas
                  }}},
            },
            flow: {
              [form]: {
                flow: {
                  [elementIdx]: {
                    instances: {
                      [instance]: {
                        childInstances: {
                          [groupElementOrder]: {
                            value: { $set: value },
                            elementType: { $set: elementType },
                          }}}}}}}}}},
      },
    })
  }

  const onChangeValue = (
    value: ElementValue,
    flowElementId: FlowFormElement['id'],
    needSave = true,
    updatedData?: AssignmentData,
  ) => {
    const toSave = value === null ? '' : value
    const saveValue = Array.isArray(toSave) ? toSave : [String(toSave)]
    setCallApplication((prevApp) => {
      const { step, form, assignment } = prevApp.editorSelector
      const flowStep = prevApp.call!.flow[step]
      const elementIdx = flowStep.flow[form].flow.findIndex(
        (el: ApplicationFormElement) => el.id === flowElementId)

      if(!prevApp.call?.flow || elementIdx === -1) return prevApp

      let updatedApplication = prevApp

      /* Check if there are elements that have sum conditions needed to be updated */
      //TODO: Do the same for onchangeGroupElement?
      /* TODO: Group elements with sums are not being checked!  */
      for (let f = 0; f < flowStep.flow.length; f++) {
        const flowForm = flowStep.flow[f]
        const foundSums = flowForm.flow.filter((el) =>
          !!el.effects.find((condition) => condition.effect === 'sum')?.validation?.elementsIds?.includes(flowElementId) ||
          !!el.effects.find((condition) => condition.effect === 'sum')?.validation?.elements?.map((e) => e.id).includes(flowElementId),
        )
        if(foundSums.length > 0) {
          for (let i = 0; i < foundSums.length; i++) {
            const foundSumIdx = flowForm.flow.findIndex((el) => el.id === foundSums[i].id)
            const foundSum = flowForm.flow[foundSumIdx]
            const newConditionValues = getConditionsValues(
              prevApp.call, foundSum, prevApp.call.flow[step].id)
            const { sum } = newConditionValues // Recompute sum value
            updatedApplication = update(updatedApplication, {
              call: {
                flow: {
                  [step]: {
                    assignments: {
                      [assignment]: {
                        data: {
                          $apply: function(datas: AssignmentData[]) {
                            const foundData = (datas || []).findIndex((data) => (data.flowElementId === foundSum.id))
                            const newDatas = [...(datas || [])]
                            if(foundData === -1)
                              newDatas.push(
                                { order: newDatas.length + 1,
                                  flowElementId: foundSum.id,
                                  needSave,
                                  input: { values: [String(sum)] } as AssignmentInput,
                                } as AssignmentData)
                            else newDatas.splice(foundData, 1, {
                              ...newDatas[foundData],
                              needSave,
                              input: { ...newDatas[foundData].input, values: [String(sum)] } as AssignmentInput,
                            })
                            return newDatas
                          }}},
                    },
                    flow: {
                      [f]: {
                        flow: {
                          [foundSumIdx]: {
                            value: { $set: sum }}}}}}},
              },
            })
          }
        }
      }
          
      return update(updatedApplication, {
        needSave: { $set: needSave ? true : updatedApplication.needSave },
        call: {
          flow: {
            [step]: {
              assignments: {
                [assignment]: {
                  data: { // Change the corresponding AssignmentData for the BE
                    $apply: function(datas: AssignmentData[]) {
                      const foundData = (datas || []).findIndex((data) => data.flowElementId === flowElementId)
                      const newDatas = [...(datas || [])]
                      if(foundData === -1)
                        newDatas.push(
                          { order: newDatas.length + 1,
                            flowElementId,
                            needSave,
                            input: { values: saveValue } as AssignmentInput,
                          } as AssignmentData)
                      else newDatas.splice(foundData, 1, {
                        ...newDatas[foundData],
                        needSave,
                        input: { ...newDatas[foundData].input, values: saveValue } as AssignmentInput,
                        ...(updatedData || {}),
                      })
                      return newDatas
                    }}},
              },
              flow: {
                [form]: {
                  flow: {
                    [elementIdx]: {
                      value: { $set: value }}}}}}},
        },
      })})
  }

  const addGroupInstance = (
    groupElement: ApplicationFormElement,
    instance: number,
  ) => {
    setCallApplication((prevApp) => {
      const { step, form, assignment } = prevApp.editorSelector
      const currentAssignment = prevApp.call?.flow[step].assignments[assignment]
      const groupElementIdx = prevApp.call!.flow[step].flow[form].flow.findIndex(
        (el: ApplicationFormElement) => el.id === groupElement.id)
      if(!currentAssignment || groupElementIdx === -1) return prevApp
      const assignmentId = currentAssignment.id
      const groupFeId = `create-${groupElement.id}-${instance}`
      const futureGroupData = { id: groupFeId, feId: groupFeId, flowElementId: groupElement.id, needCreate: true, isEditable: true, input: { values: [''], order: instance } as AssignmentInput } as unknown as AssignmentData
      const futureChildrenGroupData = groupElement.flow.map((childEl) => ({
        id: `create-${childEl.id}-${instance}`, feId: `create-${childEl.id}-${instance}`,
        flowElementId: childEl.id, dataGroupId: futureGroupData.id, noData: true, isEditable: true, order: childEl.order, input: { values: [''], order: childEl.order } as AssignmentInput,
      } as unknown as AssignmentData))
      futureChildrenGroupData.push(futureGroupData)
      return update(prevApp, {
        needSave: { $set: true },
        call: {
          flow: {
            [step]: {
              assignments: {
                [assignment]: {
                  data: { $push: futureChildrenGroupData }},
              },
              flow: {
                [form]: {
                  flow: {
                    [groupElementIdx]: {
                      instances: {
                        $push: [{
                          id: instance,
                          flowElementId: groupElement.id,
                          isEditable: futureGroupData.isEditable,
                          assignmentId,
                          childInstances: groupElement.flow.map((child, idx: number) =>
                            ({
                              id: idx,
                              flowElementId: child.id,
                              assignmentId,
                              dataGroupId: futureGroupData.id,
                              dataGroup: { flowElementId: futureGroupData.flowElementId },
                              elementType: child.elementType as FORM_ELEMENT_TYPES,
                              value: undefined,
                              noData: true,
                            }),
                          ),
                        }],
                      }}}}}}}},
      })
    })
  }

  const deleteInstanceAssignmentDataApi = async (
    id: FlowAssignment['id'],
    dataGroupId: number,
    elementId: number,
    instance: number,
    dataIds: number[],
    dataToSave: AssignmentData[],
    updatedDataIds: AssignmentData['id'][],
  ) => {
    try {
      await deleteAssignmentData({ id, dataIds }).unwrap()
      /* Update the order of the other instances */
      if(dataToSave.length) await saveAssignment({ id, data: dataToSave, newGroups: [] }).unwrap()
      dispatch({ type: 'notification', payload: { type: NotificationType.success, msg: 'Deletion Successful' } })
      setCallApplication((prevApp) => {
        const { step, form, assignment } = prevApp.editorSelector
        const currentAssignment = prevApp.call?.flow[step].assignments[assignment]
        const elementIdx = prevApp.call!.flow[step].flow[form].flow.findIndex(
          (el: ApplicationFormElement) => el.id === elementId)
        if(!currentAssignment || elementIdx === -1) return prevApp
        return update(prevApp, {
          needSave: { $set: true },
          call: {
            flow: {
              [step]: {
                assignments: {
                  [assignment]: {
                    data: { // Remove assignmentDatas
                      $apply: (datas: AssignmentData[]) => {
                        const filteredDatas = datas.filter(
                          (data) => (!dataIds.includes(data.id!) && (data.dataGroupId !== dataGroupId))) || []
                        const updatedDatas = filteredDatas.map((data: AssignmentData) =>
                          (updatedDataIds || []).includes(data.id!) ? 
                            ({
                              ...data, input: { ...data.input, order: (data.input?.order || 1) - 1 },
                            }) as AssignmentData
                            : data)
                        return updatedDatas
                      }}},
                },
                flow: {
                  [form]: {
                    flow: {
                      [elementIdx]: {
                        instances: {
                          $splice: [[instance, 1]], // Remove entire instance
                        }}}}}}},
          },
        })
      })
      
    } catch (err) {
      console.log(err)
    }
  }

  const removeGroupInstance = (
    groupElementId: ApplicationFormElement['id'],
    instance: number,
  ) => {
    setCallApplication((prevApp) => {
      const { step, form, assignment } = prevApp.editorSelector
      const currentAssignment = prevApp.call?.flow[step].assignments[assignment]
      /* Find the instance group data all associated to them */
      const groupData = currentAssignment?.data.find(
        (data: AssignmentData) => (data.flowElementId === groupElementId) && (data.input?.order === instance))
      const elementIdx = prevApp.call!.flow[step].flow[form].flow.findIndex(
        (el: ApplicationFormElement) => el.id === groupElementId)
      if(!currentAssignment || !groupData || (elementIdx === -1)) return prevApp
      /* If we are removing an instance that was not saved to the BE */
      if(groupData.feId) {
        const toRemoveDataFeIds = currentAssignment.data.filter(
          (data: AssignmentData) => data.dataGroupId === groupData.feId)
          .map((childData: AssignmentData) => childData.feId) as string[]
        toRemoveDataFeIds.push(groupData.feId)
        /* Get groupdatas after the one removed */
        const otherInstanceGroupDatas = currentAssignment.data.filter(
          (data: AssignmentData) => data.flowElementId === groupElementId && (data.input?.order || 0) > instance) || []
        const newChildDatas: AssignmentData[] = []
        const toRemoveChildDatasFeIds: string[] = []
        /* Need to update the FE ids of everyone affected */
        for(const groupAssignmentData of otherInstanceGroupDatas) {
          const groupInstance = (groupAssignmentData.input?.order || 1) - 1
          const childDatas = currentAssignment.data.filter(
            (data) => data.dataGroupId === groupAssignmentData.feId)
          toRemoveChildDatasFeIds.push(...((childDatas.map((d) => d.feId) as string[]) || []))
          const updatedChildDatas = childDatas.map((child) => ({
            ...child, id: `create-${child.flowElementId}-${groupInstance}`, feId: `create-${child.flowElementId}-${groupInstance}`,
            dataGroupId: `create-${groupAssignmentData.flowElementId}-${groupInstance}`,
          })) || []
          newChildDatas.push(...(updatedChildDatas as unknown as AssignmentData[]))
        }
        /* Change FE ids of group parents and their order */
        const newGroupDatas = otherInstanceGroupDatas.map((groupD) => ({
          ...groupD, 
          id: `create-${groupD.flowElementId}-${(groupD.input?.order || 1) - 1}`, feId: `create-${groupD.flowElementId}-${(groupD.input?.order || 1) - 1}`,
          input: { ...groupD.input, order: (groupD.input?.order || 1) - 1},
        }))
        const toRemoveGroupDatasFeIds = otherInstanceGroupDatas.map((gd) => gd.feId || '') || []

        toRemoveDataFeIds.push(...toRemoveGroupDatasFeIds, ...toRemoveChildDatasFeIds)
        return update(prevApp, {
          needSave: { $set: true },
          call: {
            flow: {
              [step]: {
                assignments: {
                  [assignment]: {
                    data: { // Remove assignmentDatas
                      $apply: (oldDatas: AssignmentData[]) => {
                        /* Remove the instance that was deleted */
                        const filteredDatas = oldDatas.filter((oldData) => 
                          (!toRemoveDataFeIds.includes(oldData.feId!))) || []
                        /* Update the other group instances by replacing their data by the new */
                        filteredDatas.push(
                          ...(newGroupDatas as unknown as AssignmentData[]), ...newChildDatas)
                        return filteredDatas
                      }}},
                },
                flow: {
                  [form]: {
                    flow: {
                      [elementIdx]: {
                        instances: {
                          $splice: [[instance, 1]], // Remove entire instance
                        }}}}}}},
          },
        })
      } else {
        if(!groupData.id) return prevApp
        const dataIds = currentAssignment.data.filter(
          (data: AssignmentData) => (data.dataGroupId === groupData.id) && !!data.id)
          .map((childData: AssignmentData) => childData.id) as number[]
        dataIds.push(groupData.id as number)
        /* Need to find all assignment datas from the same group but instances above the deleted one */
        const groupDatas = currentAssignment.data.filter(/* Change the order of all instances */
          (data: AssignmentData) => (data.flowElementId === groupElementId) && ((data.input?.order || 0) > instance))
          .map((filteredData: AssignmentData) => (
            { ...filteredData, input: { ...filteredData.input, order: (filteredData.input?.order || 1) - 1}}
          ) as AssignmentData) || []
        const updatedDataIds = groupDatas?.map((d) => d.id) || []
        /* Update the order of all assignment Datas already saved that are after the deleted instance */
        const dataToSave = updatedDataIds?.length > 0 ? groupDatas.filter((data) => !data.feId && !data.needCreate) : []
        /* Request to update BE */
        deleteInstanceAssignmentDataApi(
          currentAssignment.id, groupData.id, groupElementId, instance, dataIds, dataToSave, updatedDataIds)
        return prevApp
      }
    })
  }
  
  const addAssignmentData = (
    assignmentData: AssignmentData,
    flowElementId: FlowFormElement['id'],
    value: ElementValue,
  ) => {
    setCallApplication((prevApp) => {
      const { step, form, assignment } = prevApp.editorSelector
      const flowStep = prevApp.call!.flow[step]
      const elementIdx = flowStep.flow[form].flow.findIndex(
        (el: ApplicationFormElement) => el.id === flowElementId)
      if(!prevApp.call || elementIdx === -1) return prevApp
      return update(prevApp, {
        call: {
          flow: {
            [step]: {
              assignments: {
                [assignment]: {
                  data: {
                    $push: [assignmentData],
                  }},
              },
              flow: {
                [form]: {
                  flow: {
                    [elementIdx]: {
                      value: { $set: value }}}}},
            }}},
      })})
  }

  const addGroupAssignmentData = (
    prevApp: CallApplicationState,
    assignmentDatas: AssignmentData[],
    groupId: number,
    groupElementOrder: number,
    instance: number,
    elementType: FORM_ELEMENT_TYPES,
    value: ElementValue,
    maintainNeedSave?: boolean,
  ) => {
    const { step, form, assignment } = prevApp.editorSelector
    if(!prevApp.call?.flow) return prevApp
    const elementIdx = prevApp.call.flow[step].flow[form].flow.findIndex(
      (el: ApplicationFormElement) => el.id == groupId)
    if(elementIdx < 0) return prevApp
    return update(prevApp, {
      needSave: { $set: maintainNeedSave ? prevApp.needSave : true},
      call: {
        flow: {
          [step]: {
            assignments: {
              [assignment]: {
                data: {
                  $push: assignmentDatas,
                }},
            },
            flow: {
              [form]: {
                flow: {
                  [elementIdx]: {
                    instances: {
                      [instance]: {
                        childInstances: {
                          [groupElementOrder]: {
                            value: { $set: value },
                            elementType: { $set: elementType },
                          }}}}}}}}}}},
    })
  }

  const onChangeGroupElementValue = async (
    groupId: ApplicationFormElement['id'],
    childElementId: ApplicationFormElement['id'],
    groupElementOrder: ApplicationFormElement['order'],
    instance: number,
    value: ElementValue,
    elementType: FORM_ELEMENT_TYPES,
  ) => {
    try {
      const saveValue = Array.isArray(value) ? value : [String(value || '')]  
      setCallApplication((prevApp) => {
        const { step, form, assignment } = prevApp.editorSelector
        const currentAssignment = prevApp.call?.flow[step].assignments[assignment]
        if(!currentAssignment) return prevApp
        /* Check if the parent instance (Group) as an assignment data */
        const dataGroupId = currentAssignment.data.find(
          (data: AssignmentData) => ((data.flowElementId == groupId) && (data.input?.order == instance)))?.id
        if(dataGroupId) { 
          /* See if there is a child element assignment data associated to this instance */
          const childData = currentAssignment.data.find(
            (data: AssignmentData) => data.flowElementId === childElementId && data.dataGroupId === dataGroupId)
          if(!childData) {/* If not, create an assignment Data */
            const assignmentDatas = [
              {
                flowElementId: childElementId,
                needSave: true,
                dataGroupId,
                order: groupElementOrder,
                isEditable: true,
                input: { values: saveValue, order: groupElementOrder } as AssignmentInput,
              } as unknown as AssignmentData,
            ]
            return addGroupAssignmentData(
              prevApp, assignmentDatas, groupId, groupElementOrder, instance, elementType, value)
          } else {
            return changeGroupAssignmentValue(prevApp,
              groupId, groupElementOrder, childData?.id || childData, instance, value, elementType)
          }
        } else { /* Create the group assignment data to create */
          const flowForm = prevApp.call?.flow[step].flow[form]
          const groupElementIdx = flowForm?.flow.findIndex((el) => el.id == groupId) || -1
          const groupElement = groupElementIdx ? flowForm?.flow[groupElementIdx] : undefined
          if(!groupElement || groupElementIdx === -1) {
            console.log('No group element found')
            return prevApp
          }
          const groupFeId = `create-${groupId}-${0}`
          const parentData = { id: groupFeId, feId: groupFeId, flowElementId: groupId, needCreate: true, input: { values: [''], order: 0 } as AssignmentInput } as unknown as AssignmentData
          const childDatas = (groupElement.flow || [])
            .filter((child) => !(child as ApplicationFormElement).config?.hidden)
            .map((child, order) => ({ id: `create-${child.id}-${0}`, feId: `create-${child.id}-${0}`, noData: true, flowElementId: child.id, dataGroupId: groupFeId, order, input: { values: [''], order } as AssignmentInput } as unknown as AssignmentData))
          const newDatas = [parentData, ...childDatas.map((data) => 
            childElementId == data.flowElementId ?
              ({...data,
                input: { values: saveValue, order: groupElementOrder } as AssignmentInput,
                needSave: true,
                isEditable: true,
              }) 
              : data)]
          return update(prevApp, {
            call: {
              flow: {
                [step]: {
                  assignments: {
                    [assignment]: {
                      data: {
                        $push: newDatas,
                      }},
                  },
                  flow: {
                    [form]: {
                      flow: {
                        [groupElementIdx]: {
                          instances: {
                            $set: [{
                              id: 0,
                              flowElementId: groupElement.id,
                              isEditable: true,
                              assignmentId: currentAssignment.id,
                              childInstances: groupElement.flow.map((child, idx: number) =>
                                ({
                                  id: idx,
                                  flowElementId: child.id,
                                  assignmentId: currentAssignment.id,
                                  dataGroupId: groupFeId,
                                  dataGroup: { flowElementId: groupElement.id },
                                  elementType: child.elementType as FORM_ELEMENT_TYPES,
                                  value: childElementId == child.id ? value : '',
                                  noData: true,
                                }),
                              ),
                            }],
                          }}}}}}}},
          })
        }
      })
    } catch (err) {
      console.log(err)
    }
  }

  const scrollToTop = () => {
    document.body.dispatchEvent(new Event('scrollToTop', {
      bubbles: true,
      cancelable: false,
      composed: false,
    }))
  }

  const submitAssignmentApi = async (
    id: FlowAssignment['id'],
    data: AssignmentData[] = [],
    newGroups: (AssignmentData & { children: AssignmentData[] })[] = [],
  ) => {
    try {
      await submitAssignment({ id, data, newGroups }).unwrap()
      setDraft(false)
      setCanDelete(false)
      setCallApplication((prevApp) => {
        const { step, assignment } = prevApp.editorSelector
        setDraft(false)
        return update(prevApp, {
          needSave: { $set: false },
          call: {
            flow: {
              [step]: {
                assignments: {
                  [assignment]: {
                    status: { $set: FLOW_STATUS.SUBMITTED },
                  }}}} },
        })
      })
      /* TODO: Should we also GET application after a time so that automations run? */
      //delayedGetApplication()
      dispatch({ type: 'notification', payload: { type: NotificationType.success, msg: 'Submission successful' } })
    } catch (err) {
      console.log(err)
    }
  }

  const saveAssignmentApi = async (
    id: FlowAssignment['id'],
    allSavedIds: (string | number | undefined)[],
    clickedSave: boolean,
    data: (Omit<AssignmentData, 'flowElement' | 'dataGroup'>)[] = [],
    newGroups: (AssignmentData & { children: AssignmentData[] })[] = [],
    newEditorSelector?: CallApplicationState['editorSelector'],
    scrollId = '',
    scrollTop = false,
  ) => {
    try {
      const apiDatas = data.length || newGroups.length ? await saveAssignment({ id, data, newGroups }).unwrap() : []
      if(clickedSave) dispatch({ type: 'notification', payload: { type: NotificationType.success, msg: 'Changes saved' } })
      if(scrollId) {
        const divElement = document.getElementById(scrollId)
        divElement?.scrollIntoView({ behavior: 'smooth', block: 'center' })
      }
      if(scrollTop) scrollToTop()
      if(apiDatas.length || newEditorSelector)
        setCallApplication((prevApp) => {
          if(!apiDatas.length) return { ...prevApp, editorSelector: newEditorSelector ?? prevApp.editorSelector}
          const { step, assignment } = prevApp.editorSelector
          const currentAssignment = prevApp.call?.flow[step].assignments[assignment]
          if(!currentAssignment) return prevApp
          const allDatas = [
            ...(currentAssignment?.data || []).filter((data) => !allSavedIds?.includes(data.id)),
            ...apiDatas,
          ] // Filter out the ones that were already saved
          const { allSavedIds: futureSaveIds } = getGroupsToCreate(allDatas)
          const toSaveDatas = allDatas.filter((d) => !!d.needSave && !d.feId && !d.needCreate).map((d) => d.id)
          const needSave: boolean = !!(futureSaveIds.length || toSaveDatas.length)
          return update(prevApp, {
            needSave: { $set: needSave },
            editorSelector: { $set: newEditorSelector ?? prevApp.editorSelector },
            call: { 
              flow: {
                [step]: {
                  assignments: {
                    [assignment]: {
                      data: { $set: allDatas },
                    }}}} },
          })
        })
      return apiDatas || []
    } catch (err) {
      console.log(err)
      return []
    }
  }

  const onSaveHandle = (
    clickedNext?: boolean,
    newForm?: number,
    validate = true,
    submit?: boolean,
  ) => {
    setTimeout(() => {
      setCallApplication((prevApp) => {
        const { step, form, assignment } = prevApp.editorSelector
        const currentAssignment = prevApp.call?.flow[step].assignments[assignment]
        if(!prevApp.call || !currentAssignment?.data) return prevApp
  
        const clickedForm = newForm !== undefined
        const clickedSave = !clickedNext && !clickedForm && !submit
        const { callState, anyError, currentFormError } = validateAssignment(
          prevApp.call,
          step,
          form,
          cutoff?.resources || [], // TODO: cutoff could be memoized
        )
  
        if(submit) {
          if(anyError) {
            if(currentFormError) {
              const divElement = document.getElementById(String(currentFormError))
              divElement?.scrollIntoView({ behavior: 'smooth', block: 'center' })
            }
            dispatch({ type: 'notification', payload: { type: NotificationType.error, msg: 'Solve all errors before submitting' } })
          } else {
            const createInstances = currentAssignment.data.filter((data) => !!data.needCreate)
            const newGroups = createInstances.map((groupFeData) => ({
              ...groupFeData, id: undefined, feId: undefined, needCreate: undefined,
              children: currentAssignment.data.filter((data) => data.feId &&(data.dataGroupId == groupFeData.feId))
                .map((child) => 
                  ({ ...child, id: undefined, feId: undefined, dataGroupId: undefined, dataGroup: undefined })),
            })) || []
            const saveDatas = currentAssignment.data.filter((data) => !!data.needSave && !data.feId && !data.needCreate)
            /* Request to save data to the BE */
            submitAssignmentApi(
              currentAssignment.id,
              saveDatas,
              newGroups,
            )
            return update(prevApp, { call: { $set: callState } })
          }
        }
  
        const nextNoValidation = clickedNext && (!anyError || !validate)
  
        if(!prevApp.needSave && clickedSave) { // Check if changes flag is true, only save in that case
          if(clickedSave) dispatch({ type: 'notification', payload: { type: NotificationType.success, msg: 'Changes saved' } })
          if(nextNoValidation) {
            scrollToTop()
            return { ...prevApp, editorSelector: { ...prevApp.editorSelector, form: form + 1 }}
          }
          return prevApp
        }
  
        const { newGroups, allSavedIds } = getGroupsToCreate(currentAssignment.data)
  
        //Only send assignmentDatas with needSave flag
        const saveDatas = currentAssignment.data.filter((data) => !!data.needSave && !data.feId && !data.needCreate)
          .map((data) => ({ ...data, dataGroup: undefined, flowElement: undefined, values: undefined }))
        allSavedIds.push(...saveDatas.map((d) => d.id))
        const newEditorSelector = clickedForm ? { ...prevApp.editorSelector, form: newForm } : undefined
        /* Request to save data to the BE */
        saveAssignmentApi(
          currentAssignment.id,
          allSavedIds,
          clickedSave,
          saveDatas,
          newGroups,
          clickedNext && !currentFormError && !submit ? {...prevApp.editorSelector, form: form + 1} : newEditorSelector,
          currentFormError && clickedNext ? String(currentFormError) : '',
          nextNoValidation || clickedForm,
        )
        return validate || clickedNext ? update(prevApp, { call: { $set: callState } }) : prevApp
      })
    }, 200)
  }

  const onAssignOther = async (userId: number, partitionId: Nullable<string>, makeLead = false, role: ROLES) => {
    try {
      await acceptAssignment({
        id: assignments[assignment].id,
        accepted: FLOW_STATUS.REFUSED,
        reason: '',
      }).unwrap()
      const newAssignment = await assignUser({
        id: Number(callApplication.id),
        flowElementId: flowStep.id,
        userId,
        partitionId,
        makeLead,
        role,
      }).unwrap()
      setCallApplication((prevApp) => update(prevApp, {
        call: {
          flow: {
            [prevApp.editorSelector.step]: {
              assignments: { $push: [newAssignment] },
            }}}}))
      /* TODO: Prevent doing the Get Application  */
      delayedGetApplication()
      setAssignOtherOpen(false)
      dispatch({ type: 'notification', payload: { type: NotificationType.success, msg: 'User assigned' } })
    } catch (err) {
      console.log(err)
    }

  }

  const handleAssign = async (userId: number, partitionId: Nullable<string>, makeLead = false, role: ROLES) => {
    try {
      const apiAssignment = await assignUser({
        id: Number(callApplication.id),
        flowElementId: flowStep.id,
        userId,
        partitionId,
        makeLead,
        role,
      }).unwrap()
      const newAssignment = { ...apiAssignment, data: [] } as FlowAssignment
      dispatch({ type: 'notification', payload: { type: NotificationType.success, msg: 'Assignment created' } })
      setCallApplication((prevApp) => update(prevApp, {
        call: {
          flow: {
            [prevApp.editorSelector.step]: {
              assignments: { $push: [newAssignment] },
            }}}}))
    } catch (err) {
      console.log(err)
    }
  }

  const onDeleteApplication = async () => {
    try {
      await deleteApp(Number(callApplication.id)).unwrap()
      dispatch({ type: 'notification', payload: { type: NotificationType.success, msg: 'Application deleted' } })
      navigate(Routes.APPLICATIONS)
    } catch (err) {
      console.log(err)
    }
  }

  const handleDownloadClick = async (uploadId: number, elementId: number) => {
    try {
      const elementName = (flowStep?.flow || [])[form]?.flow.find((el) => el.id === elementId)?.title || ''
      await downloadFile({ uid: callApplication?.uid || '', uploadId, elementName }).unwrap()
    } catch (err) {
      console.log(err)
    }
  }

  const downloadConsolidated = async (uid: string) => {
    try {
      await downloadPdf(uid).unwrap()
    } catch (err) {
      console.log(err)
    }
  }

  const delayedGetApplication = () => {
    /* To make sure the BE does the necessary automations */
    dispatch({ type: 'activateLoading' })
    window.setTimeout(async () => {
      try {
        await refetch().unwrap()
      } catch (err) {
        console.log(err)
      }
      dispatch({ type: 'stopLoading' })
    }, 5000)
  }

  const onAccept = async () => {
    if(acceptOpen || !showCoi) {
      try {
        await acceptAssignment({
          id: assignments[assignment].id,
          accepted: FLOW_STATUS.ACCEPTED,
          reason: '',
        }).unwrap()
        delayedGetApplication()
        setAcceptOpen(false)
      } catch (err) {
        console.log(err)
      }
    }
    else setAcceptOpen(true)
  }

  const handleReject = async (reason: string) => {
    try {
      await acceptAssignment({
        id: assignments[assignment].id,
        accepted: FLOW_STATUS.REFUSED,
        reason,
      }).unwrap()
      delayedGetApplication()
      setRejectOpen(false)
    } catch (err) {
      console.log(err)
    }
  }

  const handleAssignSubmit = (userID: number, partitionId: Nullable<string>, makeLead = false, role: ROLES) => {
    handleAssign(userID, partitionId, makeLead, role)
    setAssignOpen(false)
  }

  const setLeadAssignment = async (assignment: FlowAssignment) => {
    try {
      await updateLeadAssignment({
        applicationId: Number(callApplication.id),
        assignmentId: assignment.id,
        flowElementId: flowStep.id,
      }).unwrap()
      dispatch({ type: 'notification', payload: { type: NotificationType.success, msg: 'Assignment updated' } })
      //TODO: Prevent doing the Get Application?
      await refetch().unwrap()
    } catch (err) {
      console.log(err)
    }
  }

  if((error as ApiBaseError)?.status == 400 || (error as ApiBaseError)?.status == 500) return <ErrorNotice message='An error occurred while fetching the application' />
  if((error as ApiBaseError)?.status == 404) return <NotFound404 message='The application you’re looking for couldn’t be found' />
  if(!call?.flow || !call?.title) return <Loading loading />

  const { logo = '', title = '', flow, cutoffs = [] } = call
  const lastForm = form === ((flowStep.flow || []).length - 1)

  const { assign = [], assignable = [] } = permissions
  const isUnlockable = flowStep.config?.isUnlockable
  const hasLead = flowStep.config?.hasLead
  const showCoi = flowStep.config?.showCoi
  const coi = flowStep.config?.coi || ''
  const canAssign = user && assign.includes(user?.role)
    && (!isUnlockable || (isUnlockable && callApplication.unlocked.includes(flowStep.id)))

  const currentAssignment = assignments && (assignments.length >= assignment + 1) ? assignments[assignment] : undefined
  const isOwner = (currentAssignment?.ownerId || 0) == user?.id

  /* Check if Call is closed when on applicant draft */
  const callClosed = canDelete ? !getCallStatus(call as FlowCall) : false
  const applicationCutoffs = applicationCutOff(call.cutoffs, callApplication.cutoffId, canDelete)
  const openingSoon = applicationCutoffs?.length > 1 ? !!applicationCutoffs[1] : false
  const cutoff = applicationCutoffs?.length ? applicationCutoffs[0] : undefined

  let working = false
  let canAcceptAssignment = false
  if(currentAssignment) {
    const { status, deadline } = currentAssignment
    working = !callClosed && canEditAssignment(isOwner, status, deadline, cutoffs)
    canAcceptAssignment = isOwner && (status == FLOW_STATUS.ASSIGNED)
  }

  const collapseFlow = flow.map((flowStep, i: number) => ({
    id: flowStep.id,
    order: flowStep.order,
    title: flowStep.title,
    error: flowStep.error,
    type: flowStep.type,
    hideChilds: isOwner ? false : (!(currentAssignment && currentAssignment.data?.length) && i === step),
    flow: flowStep.flow?.map((flowForm, j: number) => ({
      id: flowForm.id,
      order: flowForm.order,
      title: flowForm.title,
      error: flowForm.error,
      flow: [],
      type: flowForm.type,
      workingStep: (j <= form) || !isOwner,
    })) || [],
    workingStep: i <= workingStep,
  })) as CollapseItem[]

  const isExpired = () => {
    // Calculate the expire day
    if (assignments && assignments.length > 0) {
      const [firstAssignment] = assignments
      if(firstAssignment.status !== FLOW_STATUS.DRAFT) return false
      const today = new Date()
      const expireAt = moment(firstAssignment.createdAt).add(1, 'year')
      return moment(today).isAfter(moment(expireAt).subtract(3, 'months'))
    }

    return false
  }

  const onBackHandle = (form: number) => {
    handleSelect(form - 1, ELEMENT_TYPES.FORM)
  }

  const onNextHandle = async (working: boolean, form: number, lastForm: boolean, validate?: boolean) => {
    /* This is to make sure with do not submit data before saving all the changes */
    if(working && !canAcceptAssignment) {
      if(lastForm) {
        //Submit the assignment
        onSaveHandle(true, undefined, true, true)
      } else {
        onSaveHandle(true, undefined, validate)
      }
    } else {
      handleSelect(form + 1, ELEMENT_TYPES.FORM)
    }
  }

  const handleSelect = async (order: number, type: string) => {
    if(loading) return
    if(working && type == 'form' && !canAcceptAssignment) {
      onSaveHandle(false, order, false)
    } else {
      setTimeout(() => {
        changeSelection(order, type)
        scrollToTop()
      }, 200)
    }
  }

  let allResources: CallPartitionResource[] = []
  cutoffs.forEach((cutOff) => {
    allResources = [...allResources, ...cutOff.resources]
  })

  const assignmentPartition =
    allResources.find((resource) => resource.partition?.id == currentAssignment?.partitionId)?.partition

  const canMakeLead = (user?.role == ROLES.AC_CHAIR) && flowStep.config?.hasLead

  if(isUninitialized || isLoading) return <Loading loading />

  return <>
    <Banner small isMobile={isMobile}>
      <Grid container alignItems='center'>
        <Grid item>
          <$CallImage src={logo ? `${CDN_AVATARS}/${logo}` : undefined}>
            {logo ?  '' : title.substring(0, 2).toLocaleUpperCase()}
          </$CallImage>
        </Grid>
        <Grid item>
          <$PageTitle>{title}</$PageTitle>
          <$ProposalID>Proposal ID: {draft ? `DRAFT-${callApplication?.id}`: callApplication?.uid}</$ProposalID>
          {!workingStep && /* Only shows when first step is being edited */
              <$Chip
                color={
                  callClosed ? openingSoon ? newTheme.colors.feedback.warning : newTheme.colors.feedback.error :
                    draft ? newTheme.colors.neutral.light : newTheme.colors.success.light
                }>
                {callClosed ? openingSoon ? 'Opening Soon' : 'Call Closed' : draft ? 'Draft' : 'Submitted'}
              </$Chip>}
        </Grid>
      </Grid>
    </Banner>
    <$CallApplication container justifyContent={isMobile ? 'space-between' : 'space-around'}>
      <CallFlowContainer
        isMobile={isMobile}
        flow={collapseFlow}
        workingStep={workingStep}
        step={step}
        form={form}
        handleSelect={handleSelect}
        open={openDrawer}
        setOpen={() => setOpenDrawer((o) => !o)}
        call={call}
        handleOpenAssign={() => setAssignOpen(true)}
        selectedAssignment={assignment}
        assignments={assignments}
        onDeleteApplication={onDeleteApplication}
        canAssign={canAssign}
        canDeleteApplication={canDelete}
        setLeadAssignment={setLeadAssignment}
        assignmentPartition={assignmentPartition}
        canMakeLead={canMakeLead}
        hasLead={hasLead}
        handleDownloadClick={handleDownloadClick}
        proposalId={callApplication?.uid}
        documents={documents || []}
        downloadConsolidated={downloadConsolidated}
        loading={loading}
      />
      {!openDrawer && <$FormContainer item isMobile={isMobile} xs={isMobile ? 11 : 5}>
        {isExpired() && (
          <$AlertContent>
            <$WarningContainer item>
              <$WarningGrid container>
                <SvgIcon name='warning' color={newTheme.colors.feedback.error} size={16} />
                <$Warning>{'This draft proposal will be deleted soon. If you want to keep it please contact support.'}</$Warning>
              </$WarningGrid>
            </$WarningContainer>
          </$AlertContent>
        )}
        <ApplicationFlowStep
          call={call}
          step={step}
          form={form}
          profile={profile as User}
          user={user as User}
          lastForm={lastForm}
          assignment={assignment}
          options_groups={options_groups}
          onChangeValue={onChangeValue}
          onChangeFile={onChangeFile}
          removeInstance={removeGroupInstance}
          addInstance={addGroupInstance}
          handleOpenAssign={() => setAssignOpen(true)}
          onBackHandle={onBackHandle}
          onNextHandle={onNextHandle}
          onSaveHandle={() => onSaveHandle(false, undefined, false)}
          disableSave={!callApplication.needSave || isDeletingData}
          onChangeGroupElementValue={onChangeGroupElementValue}
          onAccept={onAccept}
          onReject={() => setRejectOpen(true)}
          canAssign={canAssign || false}
          onDownloadClick={handleDownloadClick}
          onAssignOther={() => setAssignOtherOpen(true)}
          loading={loading}
          activeCutOff={cutoff}
          allResources={allResources}
          firstAssignmentDraft={canDelete}
          documents={documents || []}
        />
      </$FormContainer>}
      <AssignmentFlowContainer
        isMobile={isMobile}
        call={call}
        handleSelect={handleSelect}
        handleOpenAssign={() => setAssignOpen(true)}
        selectedAssignment={assignment}
        assignments={assignments}
        onDeleteApplication={onDeleteApplication}
        canAssign={canAssign}
        canDeleteApplication={canDelete}
        setLeadAssignment={setLeadAssignment}
        assignmentPartition={assignmentPartition}
        canMakeLead={canMakeLead}
        hasLead={hasLead}
        handleDownloadClick={handleDownloadClick}
        proposalId={callApplication?.uid}
        documents={documents || []}
        downloadConsolidated={downloadConsolidated}
        loading={loading}
      />
    </$CallApplication>
    {rejectOpen &&
          <NewModal open alternateTitle title='Reject Assignment' onClose={() => setRejectOpen(false)}>
            <RejectModal
              handleReject={handleReject}
              loading={loading}
              handleCancel={() => setRejectOpen(false)}
            />
          </NewModal>
    }
    {assignOtherOpen &&
          <NewModal open alternateTitle title='Assign User' onClose={() => setAssignOtherOpen(false)}>
            <AssignModal
              applicationId={callApplication?.id}
              loading={loading}
              assignable={assignable}
              assignmentOwnersStatus={assignmentOwnersStatus}
              handleAssign={onAssignOther}
              handleCancel={() => setAssignOtherOpen(false)}
              activeCutOff={cutoff}
              initialPartition={assignmentPartition}
              canHaveLead={canMakeLead}
            />
          </NewModal>
    }
    {assignOpen &&
        <NewModal open alternateTitle title='Assign User' onClose={() => setAssignOpen(false)}>
          <AssignModal
            assignable={assignable}
            loading={loading}
            applicationId={callApplication?.id}
            assignmentOwnersStatus={assignmentOwnersStatus}
            handleAssign={handleAssignSubmit}
            handleCancel={() => setAssignOpen(false)}
            activeCutOff={cutoff}
            initialPartition={assignmentPartition}
            canHaveLead={canMakeLead}
          />
        </NewModal>
    }
    {acceptOpen &&
        <NewModal open alternateTitle title='Conflict of Interest Agreement' onClose={() => setAcceptOpen(false)}>
          <AcceptModal
            coi={coi}
            loading={loading}
            handleAccept={onAccept}
            handleCancel={() => setAcceptOpen(false)}
          />
        </NewModal>
    }
  </>
}
